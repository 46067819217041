<template>
  <v-carousel
    cycle
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="item in carouselHome"
      :key="item.id"
    >
      <v-sheet
        height="100%"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-img
            :src="item.pic"
            width="100%"
            height="400"
          />
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'Carousel',
  data () {
    return {
      carouselHome: [
        {
          id: 1,
          pic: 'img/hospital_cassems.jpg'
        }
      ]
    }
  }
}
</script>
