<template>
  <v-row>
    <v-col>
      <div id="newsTitle">
        <h1 class="text-uppercase px-2">
          {{ title }}
        </h1>
      </div>
      <div style="margin-right: 100px">
        <p class="py-2">
          {{ description }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SessionTitle',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: false
    }
  }
}
</script>

<style></style>
